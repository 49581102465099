.slick-slide > div {
  margin: 0 10px;
  /* background-color: yellow; */
}
.slick-list {
  margin: 0 -10px;
  /* background-color: green; */
}
.slick-prev:before {
  content: "<";
  color: red;
  /* font-size: 500px; */
}

.slick-next:before {
  content: ">";
  color: red;
  /* font-size: 500px; */
}
.slick-next {
  background-color: #007bff; /* Change the background color */

  border-radius: 50%; /* Make the button round */
  font-size: 20px; /* Change the font size */
}
.slick-arrow .slick-prev {
  color: blue;
}
