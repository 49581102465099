@keyframes run {
  0% {
    left: 0;
  }
  50% {
    left: 10%;
  }
  100% {
    left: 10%;
  }
}
#pot2 {
  color: "yellow";
  background-color: green;
}
